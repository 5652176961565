.addressLookup {
  margin-bottom: 20px;
}

.addressLookup__searchBar {
  display: flex;
  flex-direction: column;
}

.addressLookup__searchBar--hide {
  display: none;
}

.addressLookup__input {
}

.addressLookup__button {
  margin-bottom: 10px;
}

.addressLookup__action {
  text-align: right;
  border-radius: 3px;
  margin-left: auto;
  /* border: solid 1px #444; */
  padding: 6px 10px;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  @apply bg-primary text-white font-sans;
  margin-top: 10px;
}

.addressLookup__manualAction {
}

.addressLookup__fields {
  display: flex;
  flex-direction: column;
}

.addressLookup__fields--hide {
  display: none;
}
