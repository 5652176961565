/* Naked Input fields */
input,
textarea,
select {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  box-shadow: none;

  outline: none;
  background-color: transparent;
  border-style: none;
  color: #000;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

select.inputField__input {
  background-image: url("data:image/svg+xml; utf8,<svg class='w-6 h-6' fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'></path></svg>");
  background-size: 14px 14px;
  background-position: right 10px top 50%;
  background-repeat: no-repeat;
}

/* select.inputField__input:after {
  content: "";
    position: absolute;
    right: 10px;
    top: 3px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #333;
}

select.inputField__input:before {
    content: "";
    position: absolute;
    right: 10px;
    top: 8px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #f00;
}
 */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background: transparent !important;
}

form {
  .button {
    margin-top: 32px;
  }

  .button.mt-0 {
    margin-top: 0;
  }
}

/* Input Field */

.inputItem {
  position: relative;
  margin-top: 32px;
}

.inputItem--error {
  .inputError {
    display: block;
  }

  .inputField {
    border-color: #cc4b37;
  }

  /* .inputField__label {
    color: #cc4b37;
  } */
}

.inputField {
  border: solid 2px;
  position: relative;
  display: block;
  padding: 10px 15px;
  text-align: left;

  .inputField__label {
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: ease;
    transition-delay: 0s;
    position: absolute;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    font-size: 16px;
    overflow: hidden;
    pointer-events: none;
    top: 1.25rem;
    line-height: 20px;
    color: #686868;
  }

  .inputField__input {
    height: 40px;
  }
}

.inputerField--light {
  @apply text-secondary-lighter;
  .inputField__label {
    @apply text-secondary-lighter;
  }

  input {
    @apply text-secondary-lighter;
  }
}

.inputField--disabled {
  background: #efefef;
  border-color: #9e9e9e;
}

.inputField--active {
  .inputField__label {
    font-size: 0.75rem;
    top: 10px;
    line-height: 15px;
    @apply font-sans;
  }

  .inputField__input {
    height: 20px;
    margin-top: 20px;
  }
}

.inputField__input:-webkit-autofill {
  background-color: transparent;
}

.inputError,
.captchaInputError,
.fui-error-message {
  font-size: 0.75rem;
  font-weight: 500;
  color: #cc4b37;
  margin-top: 5px;
  @apply font-sans;
  text-align: left;
}

.inputError {
  display: none;
}

/* Input Grids */

.inputRow {
}

/* formSection */

.formSection {
  margin-top: 40px;
}

.formSection__heading {
  line-height: 1;
  @apply text-f3;
}

/* Label */

.inputLabel {
  @apply font-headings text-primary;
  font-size: 16px;
  margin-top: 20px;
}

/* Form upload */

.formUploadLabel {
  @apply font-headings text-primary;
  font-size: 16px;
  margin-top: 30px;
  display: block;
}

.formUpload {
  margin-top: 10px;
}

.formUpload--set {
  .formUpload__details {
    display: flex;
  }
  .formUpload__progressBar .uppy-ProgressBar {
    height: 0;
  }
}

.formUpload__dropZone {
}

.formUpload__progressBar {
  margin-top: 10px;
}

.formUpload__details {
  padding: 15px 20px;
  border-style: solid;
  border-width: 2px;
  border-radius: 3px;
  display: none;
  @apply border-primary text-primary;
}

.formUpload__name {
}

.formUpload__remove {
  color: red;
  margin-left: 15px;
  cursor: pointer;
}

@screen sm {
  .inputRow {
    display: flex;

    .inputItem {
      margin-right: 25px;
      flex-basis: 100%;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .inputLabel {
    font-size: 22px;
    margin-top: 24px;
  }

  /* formSection */
  .formSection {
    margin-top: 50px;
  }

  .formSection__heading + .inputItem,
  .formSection__heading + .inputRow .inputItem {
    margin-top: 24px;
  }
}
