@font-face {
  font-family: "lucas";
  font-weight: 400;
  src: url("../../fonts/lucas/regular.otf") format("opentype");
}

@font-face {
  font-family: "lucas";
  font-weight: 400;
  font-style: italic;
  src: url("../../fonts/lucas/mediumItalic.otf") format("opentype");
}

@font-face {
  font-family: "lucas";
  font-weight: 500;
  font-style: normal;
  src: url("../../fonts/lucas/medium.otf") format("opentype");
}

@font-face {
  font-family: "lucas";
  font-weight: 600;
  font-style: normal;
  src: url("../../fonts/lucas/bold.otf") format("opentype");
}

@font-face {
  font-family: "lucas";
  font-weight: 200;
  font-style: normal;
  src: url("../../fonts/lucas/light.otf") format("opentype");
}

@font-face {
  font-family: "lucas";
  font-weight: 700;
  font-style: normal;
  src: url("../../fonts/lucas/extraBold.otf") format("opentype");
}

@font-face {
  font-family: "lucas";
  font-weight: 700;
  font-style: italic;
  src: url("../../fonts/lucas/extraBoldItalic.otf") format("opentype");
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  src: url("../../fonts/font-awesome/font-awesome-regular.woff2")
    format("woff2");
  src: url("../../fonts/font-awesome/font-awesome-regular.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  src: url("../../fonts/font-awesome/font-awesome-solid.woff2") format("woff2");
  src: url("../../fonts/font-awesome/font-awesome-solid.woff") format("woff");
  font-weight: 700;
}
