.pageHeader {
  margin-left: auto;
  margin-right: auto;
  max-width: 100rem;
  width: 100%;
  overflow: hidden;
  margin-top: 0;
  /* margin-bottom: 2rem; */
}

.pageHeader__container {
  display: grid;
  align-items: center;
  position: relative;
  grid-template-rows: auto min-content;
  grid-template-rows: auto -webkit-min-content;
}

.pageHeader.pageHeader--bg {
  .pageHeader__tabs {
    margin-top: 0;
  }
}

.pageHeader.pageHeader--bg.pageHeader--fullWidth {
  margin-top: 0;
}

.pageHeader__content {
  padding: 6%;
  width: 100%;
}

.pageHeader.pageHeader--fullWidth {
  max-width: none;

  .pageHeader__container {
    margin-left: 0;
    margin-right: 0;
  }
}

.pageHeader__strapline {
  font-size: 20px;
}

.pageHeader__strapline + .pageHeader__heading {
  margin-top: 10px;
}

.pageHeader__subHeading {
  @apply font-headings;
  font-size: 24px;
  font-weight: normal;
  margin-top: 15px;
}

.pageHeader__heading {
  @apply text-f3;
}

.pageHeader__content {
  position: relative;
  z-index: 1;
  max-width: 100rem;
  margin-left: auto;
  margin-right: auto;

  .buttonGroup {
    margin-top: 30px;
  }
}

.pageHeader__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  z-index: 0;

  img {
    object-position: center;
    object-fit: cover;
  }
}

.pageHeader--center {
  .pageHeader__content {
    text-align: center;
  }

  .pageHeader__tabs {
    justify-content: center;
  }
}

.pageHeader--left {
  .pageHeader__content {
    text-align: left;
  }
}

.pageHeader__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
}

.pageHeader__overlay--mobile {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.pageHeader__tabs {
  display: flex;
  justify-content: flex-start;
  z-index: 2;
  font-weight: 600;
  overflow: auto;
}

.pageHeader__tabItem {
  flex: none;
  position: relative;
  transition: background 0.2s;

  &:hover {
    background: var(--default);
    transition: background 0.2s;
  }
}

.pageHeader__tabItem--active {
  background-color: var(--default);

  &:hover {
    background: var(--default);
  }
}

.pageHeader__tabLink {
  padding: 20px;
  display: block;
}

@screen md {
  .pageHeader__heading {
    @apply text-f4;
  }
}

@screen lg {
  .pageHeader {
    /* margin-bottom: 5rem; */
  }

  .pageHeader__heading {
    font-size: 4rem;
    line-height: 1;
  }

  .pageHeader--lg {
    .pageHeader__container {
      min-height: calc(43.75rem + 3vw);
    }
  }

  .pageHeader__subHeading {
    @apply text-f4;
  }

  .pageHeader__container {
    /* margin-left: calc(1.25rem + 0.25vw);
    margin-right: calc(1.25rem + 0.25vw); */
  }

  .pageHeader__content {
    padding-top: 0;
    padding-bottom: 0;
  }

  .pageHeader__image,
  .pageHeader__overlay {
    /* margin-left: calc(1.25rem + .25vw); */
    /* margin-right: calc(1.25rem + .25vw); */
  }

  /* .pageHeader__tabs {
    margin-top: 3rem;
    justify-content: center;
  } */

  .pageHeader.pageHeader--bg {
    .pageHeader__content {
      padding: 6%;
    }
  }

  .pageHeader__overlay--mobile {
    display: none;
  }

  .pageHeader__overlay {
    display: block;
  }
}

@screen xl {
  .pageHeader__heading {
    font-size: 4rem;
  }
}
