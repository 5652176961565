.circleBlock {
  /* margin:  8.474576271% 0.625rem 0; 9.322033898% */

  margin-top: 2rem;

  &:first-child {
    margin-top: 0;
  }

  .copyImage__featureCopy {
    @apply text-lg mb-0 mt-2;
  }

  .copyImage__featureHeading {
    @apply font-sans font-bold text-xl;
  }
}

.circleBlock__img {
  @apply mb-8;
  display: block;
}

.circleBlock__content {
}

.circleBlock__strapline {
  @apply text-xl;
  margin-bottom: 0.5rem;
}

.circleBlock__subHeading {
  @apply text-xl font-bold;
}

.circleBlock__heading {
  @apply text-primary font-medium;
}

.circleBlock__copy {
  @apply text-lg;
  @apply mb-9;
}

.circleBlock__body {
  .rich-text {
    margin-top: 18px;
  }
}

@screen sm {
  .circleBlock {
    display: flex;
    @apply justify-start;
    @apply items-center;
    margin: 8.474576271% 0; /* 9.322033898% */

    &:first-child {
      margin-top: 0;
    }

    &--right {
      .circleBlock__img {
        order: 2;
        margin-left: 8.474576271%;
        margin-right: 0;
      }
    }
  }

  .circleBlock__img {
    width: 42.37288136%; /* 500 / 1180 */
    margin-bottom: 0;
    margin-right: 8.474576271%; /* 100 / 1180 */
  }

  .circleBlock__content {
    width: 49.15254237%; /* 580 / 1180 */
    text-align: initial;
  }

  .circleBlock__heading {
    @apply text-f3;
  }

  .circleBlock__strapline,
  .circleBlock__content .rich-text {
    @apply text-lg;
  }
}

@screen lg {
  .circleBlock__heading {
  }

  .circleBlock__strapline {
    @apply text-xl;
  }

  .circleBlock__copy {
    font-size: 1.25rem;
    font-size: calc(1.125rem + 0.25vw);
  }

  .circleBlock__body {
    .rich-text {
      margin-top: 40px;
    }
  }
}

.circleBlock--dark {
  .floatBlock__imageContainer {
    &:after {
      @apply bg-primary;
    }
  }
}

.circleBlock--green {
  .circleBlock__heading {
    color: #349159;
  }

  .line-detail {
    &:after {
      background-color: #349159;
    }
  }

  .button {
    background-color: #349159;
  }
}

.circleBlock--purple {
  .circleBlock__heading {
    color: #56339a;
  }

  .line-detail {
    &:after {
      background-color: #56339a;
    }
  }

  .button {
    background-color: #56339a;
  }
}

.circleBlock__body {
  .copyImage__features {
    margin-top: 40px;
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    column-gap: 60px;
    row-gap: 40px;
  }

  .copyImage__icon + .copyImage__featureContent > .copyImage__featureHeading,
  .copyImage__icon
    + .copyImage__featureContent
    > .copyImage__featureCopy:first-child {
    margin-top: 20px;
  }

  .copyImage__featureHeading {
    @apply font-semibold;
  }

  .buttonGroup {
    margin-top: 40px;
  }

  .floatBlock__body {
    .copyImage__features {
      margin-top: 60px;
    }
  }

  .rich-text {
    @apply text-xl;
  }
}
