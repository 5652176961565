.acc-address-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(300px, min-content);
  column-gap: 25px;
  row-gap: 25px;
}

.acc-address__add {
  background: #fbfbfb;
  border: 2px dashed #dadada;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.acc-address__add:hover {
  background: #efefef;
}

.addressBlock {
  background: #ffffff;
  border-width: 2px;
  border-style: solid;
  @apply border-primary;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.acc-address__loading {
  opacity: 0;
  align-self: flex-end;
  /* padding: 0.7rem; */
  /* line-height: 1; */
  /* float: left; */
  transition: opacity 0.5s;
}

.acc-address__loading--show {
  opacity: 1;
}
.addressBlock__actionSep {
  margin: 0 14px;
  width: 2px;
  background: #dadada;
}

.addressBlock__actions {
  border-top-width: 2px;
  border-style: solid;
  @apply border-primary bg-primary;
  padding: 14px 24px;
  display: flex;
}

.addressBlock__action {
  @apply text-white flex items-center;

  &:focus {
    outline: none;
  }
}

.addressBlock__address {
  padding: 36px;
}

.addressBlock--selector {
  border: 2px solid #dadada;
  background: #fafafa;
  cursor: pointer;
}

.addressBlock__selector-indicator {
  display: none;
}

.addressBlock--selector.addressBlock--selected {
  @apply border-green-vibrant;
  border-style: solid;
  border-width: 3px;

  .addressBlock__actionSep {
    background: #fff;
  }

  .addressBlock__actions {
    @apply bg-green-vibrant;
    color: #fff;
    border-top: 0;
  }

  .addressBlock__selector-indicator {
    display: flex;
  }
}

@screen sm {
  .acc-address-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@screen lg {
  .acc-address-grid {
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: minmax(300px, min-content);
    column-gap: 25px;
    row-gap: 25px;
  }
}
