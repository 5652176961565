.splitCard {
  @apply flex overflow-hidden relative shadow-lg bg-secondary flex-wrap relative;

  .button {
    @apply bg-primary text-white;
  }

  .copyImage__featureCopy {
    @apply text-gray-600;
  }

  .copyImage__featureHeading {
    @apply text-primary;
  }
}

.splitCard__heading {
  font-size: 40px;
  @apply text-primary;
}

.splitCard--right {
  .splitCard__image {
    @apply order-2;
  }

  .splitCard__content {
    @apply order-1;
  }
}

.splitCard__image {
  @apply relative order-1;
}

.splitCard__content {
  padding: 40px;
  @apply order-2;
  width: 100%;
}

.splitCard__body {
  .copyImage__features {
    margin-top: 60px;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 60px;
    row-gap: 40px;
  }

  .copyImage__icon + .copyImage__featureHeading {
    margin-top: 12px;
  }

  .copyImage__featureHeading {
    @apply font-normal;
  }

  .copyImage__featureCopy {
    @apply text-sm mb-0 mt-2.5;
  }

  .buttonGroup {
    margin-top: 40px;
  }

  .rich-text {
    margin-top: 40px;
  }
}

/* @screen md {
  .splitCard__image {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
  }
} */

@screen md {
  .splitCard {
    flex-wrap: nowrap;
  }

  .splitCard__image {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
  }

  .splitCard__content {
    position: relative;
    z-index: 2;
    width: 50%;
    display: grid;
    align-items: center;
  }

  .splitCard__image {
    width: 50%;
  }

  .splitCard--two-third {
    .splitCard__content {
      padding: 40px 20px;
    }
  }

  .splitCard--one-third {
    .splitCard__content {
      padding: 40px;
    }
  }

  .splitCard__heading {
    @apply text-f1 font-bold;
  }

  .splitCard__strapline {
    @apply text-xl;
    margin-bottom: 0.5rem;
  }
}

@screen xl {
  .splitCard__image {
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
  }

  .splitCard--one-third {
    .splitCard__image {
      flex-basis: 33.333%;
    }

    .splitCard__content {
      flex-basis: 66.667%;
      padding: 80px;
      display: grid;
      grid-auto-rows: min-content;
      align-content: center;
    }
  }

  .splitCard--two-third {
    .splitCard__image {
      flex-basis: 66.667%;
    }

    .splitCard__content {
      flex-basis: 33.333%;
      padding: 60px 40px;
    }

    .splitCard__body {
      .copyImage__features {
        grid-template-columns: 1fr;
      }
    }
  }

  .splitCard__body {
    .copyImage__features {
      margin-top: 80px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 60px;
      row-gap: 80px;
    }

    .buttonGroup {
      margin-top: 40px;
    }

    .rich-text {
      @apply text-xl;
      margin-top: 40px;
    }
  }
}
