.slantedBlock {
  position: relative;
}

.slantedBlock__img {


  img {
    position: relative;
    z-index: 1;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
  }
}

.slantedBlock__content {
  z-index: 1;
  position: relative;
  padding: 20px;
}

.slantedBlock__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.slantedBlock--right {
  .slantedBlock__img {
    img {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 80%);
    }
  }

}

.slantedBlock__heading {
  @apply font-bold text-f3;

}

.slantedBlock__strapline {

}

  .slantedBlock__body {

    .rich-text {
      margin-top: 20px;
    }

    .buttonGroup {
      margin-top: 20px;
    }
  }

@screen sm {

  .slantedBlock {
/*     margin-left: calc(-1.25rem + -.25vw);
    margin-right: calc(-1.25rem + -.25vw); */
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: max-content;
  }

  .slantedBlock__img {
    overflow: hidden;
    position: relative;
    grid-area: 1 / 1 / 2 / 2;
    z-index: 1;

    img {
      clip-path: polygon(0 0, 100% 0%, 50% 100%, 0% 100%);
      min-height: 100%;
    }
  }

  .slantedBlock--left {
    .line-detail {
      &:after {
        margin-left: auto;
      }
    }
  }

  .slantedBlock--right {
    .slantedBlock__img {
      grid-area: 1 / 2 / 2 / 3;

      img {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%);
      }
    }

    .slantedBlock__content {
      grid-area: 1 / 1 / 2 / 2;
      text-align: left;
      padding: 80px 0 80px 40px;
    }

  }

  .slantedBlock__content {
    z-index: 1;
    position: relative;
    align-self: center;
    grid-area: 1 / 2 / 2 / 3;
    text-align: right;
    padding: 80px 40px 80px 0;
  }

  .slantedBlock__bg {
    position: relative;
    z-index: 0;
    grid-area: 1 / 1 / 3 / 3;
  }


}

@screen lg {
  .slantedBlock {
/*     margin-left: calc(-1.25rem + -.25vw);
    margin-right: calc(-1.25rem + -.25vw); */
    gap: 0;
    grid-auto-flow: row;
    margin-left: 0;
    margin-right: 0;
  }


  .slantedBlock__heading {
    @apply font-bold;
    font-size: 3rem;
    font-size: calc(3rem + 0.5vw);
    line-height: 4rem;
  }

  .slantedBlock__strapline {
    @apply text-lg;
    margin-bottom: 0.5rem;
  }

  .slantedBlock__body {

    .rich-text {
      @apply text-xl;
      margin-top: 30px;
    }

    .buttonGroup {
      margin-top: 40px;
    }
  }


}

@screen xl {
  .slantedBlock__img {
    overflow: hidden;
    position: relative;
    margin-top: -32px;
    margin-bottom: -32px;
    max-width: 500px;
    margin-left: 32px;

    img {
      clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    }
  }

  .slantedBlock__content {
    grid-area: 1 / 2 / 2 / 3;
    margin: 60px 60px 60px 32px;
    text-align: left;
    padding: 0;
  }

      .slantedBlock--left {
    .line-detail {
      &:after {
        margin-left: unset;
      }
    }
  }

  .slantedBlock--right {
    .slantedBlock__img {
      grid-area: 1 / 2 / 2 / 3;
      margin-right: 32px;
      margin-left: 0;

      img {
      clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);

      }
    }

    .slantedBlock__content {
      grid-area: 1 / 1 / 1 / 2;
      margin: 60px 32px 60px 60px;
      padding: 0;
    }
  }
}
