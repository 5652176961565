.siteHeader {
  z-index: 70;
  @apply text-white;
}

.siteHeader__wrapper {
  @apply bg-primary border-b border-primary-lighter;
  max-width: 1600px;
  margin: 0 auto;
}

.siteHeader__brand {
  max-width: 14rem;
  @apply block py-2.5;
}

/* Promo */

.siteHeader__promo {
  @apply font-serif pr-5 pl-5 pt-2.5 pb-2.5 text-center;
  display: block;
}

.siteHeader__promoCopy {
  &.rich-text {
    p {
      @apply text-sm;
    }
  }
}

/* Mast */
.siteHeader__mastWrapper {
  @apply border-b bg-black border-primary-lighter;
}

.siteHeader__mast {
  @apply hidden justify-between;
}

.siteHeader__mastLeft {
  .siteHeader__mastLink {
    @apply transition-colors;
  }
}

.siteHeader__mastRight {
  transition: opacity 0.5s ease-in-out;
}

.siteHeader__mastRight--notLoaded {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  .siteHeader__mastLink {
    display: none;
  }
}

.siteHeader__mastLink {
  @apply inline-block pl-4 pr-4 pt-3 pb-3;
  /* block pl-4 pr-4 pt-3 pb-3 text-gray-600 hover:text-black transition-color */
}

.siteHeader__mastLeft {
  @apply list-none text-sm font-sans flex;

  .siteHeader__mastLink {
    @apply block;
    line-height: 22px;
    padding: 3px 15px;
    border-radius: 30px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 10px;
  }
}

.siteHeader__mastRight {
  @apply list-none text-sm font-sans flex;
  line-height: 1.125rem;
}

/* Currency Switcher */

.siteHeader__currency {
  @apply flex items-center pr-4 pl-4;
}

.siteHeader__currencySwitcher {
  border: none;
  height: auto;
  padding: 12px 0;
  @apply pr-2 text-white;
  position: relative;
  cursor: pointer;
  background-image: url("data:image/svg+xml; utf8,<svg class='w-6 h-6' fill='none' stroke='white' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'></path></svg>");
  background-size: 14px 14px;
  background-position: center right;
  background-repeat: no-repeat;
  padding-right: 25px;

  &:hover {
    @apply text-white;
  }

  &:focus {
    outline: none;
  }

  /* &:after {
    content: url("data:image/svg+xml; utf8,<svg class='w-6 h-6' fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'></path></svg>");
    display: block;

  } */
}

/* Navigation */

.siteHeader__navigation {
  display: grid;
  grid-auto-flow: column;
  /* grid-template-columns: auto min-content min-content min-content; */
  grid-column-gap: 30px;
  @apply pl-5 pr-5;

  & > * {
    align-self: center;
  }
}

.siteHeader__nav {
  @apply hidden justify-center;
}

.siteHeader__navLink {
  @apply text-lg font-bold cursor-pointer;

  /* transition-color hover:text-gray-600 */
}

.siteHeader__menuToggle {
  @apply cursor-pointer;

  .fa {
    @apply text-f5;
    line-height: 0;
  }
}

.siteHeader__searchToggle {
  @apply cursor-pointer;
}

.siteHeader__basketMobile {
}

.siteHeader__basket {
  @apply block pl-4 pr-4 pt-3 pb-3;

  .siteHeader__basketIcon {
    width: 18px;
    height: 18px;
  }
}

.siteHeader__basketInfo {
  display: flex;
  align-items: center;
}

.siteHeader__basketIcon {
  width: 18px;
  height: 18px;
}

.siteHeader__basketCount {
  @apply ml-2;
}

/* Search */

body.search-active {
}

.siteHeader__search {
  @apply hidden ml-auto relative;

  &:hover {
    transition: box-shadow 200ms linear;
    @apply shadow-sm;
  }
}

.siteHeader__searchButton {
  @apply outline-none bg-gray-100 rounded-full;

  &:hover,
  &:focus {
    @apply outline-none bg-gray-200;
    transition: background 200ms linear;
  }
}

.siteHeader__searchIcon {
  @apply pt-2 pb-2 pl-2.5 pr-2.5 cursor-pointer;
  height: 40px;
}

.siteHeader__searchInput {
  @apply hidden outline-none pl-4;
}

/* Headroom */
.headroom {
  will-change: transform;
  transition: transform 200ms linear;
  position: fixed;
  width: 100%;
}

.headroom--pinned {
  transform: translateY(0%);
}

.headroom--not-top {
  top: 0;
}

.headroom--unpinned {
  transform: translateY(-100%);
}

.headroom--frozen {
  opacity: 0;
}

.siteHeader--stick {
  transform: translateY(-100%);
  transition: transform 0s;
}

.flyoutDrawer {
  transition: all;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.25s linear;
}

.flyoutDrawer--active {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.25s linear;
}

@screen sm {
  .siteHeader__mast {
    @apply block;
  }

  .siteHeader__brand {
    @apply mr-auto;
  }

  .siteHeader__menuToggle,
  .siteHeader__searchToggle {
    display: none;
  }

  .siteHeader__navigation {
    /* grid-template-columns: 1fr 2fr min-content; */
  }

  .siteHeader__nav {
    @apply grid;
    grid-auto-flow: column;
    /* grid-column-gap: 30px; */
    align-self: stretch;
    align-items: stretch;

    > li {
      display: flex;
      align-items: center;
    }
  }

  .siteHeader__basketMobile {
    display: none;
  }
}

@screen md {
  .siteHeader__mast {
    @apply flex;
  }

  .siteHeader__navigation {
    /* grid-template-columns: 1fr 2fr 1fr; */
  }

  .siteHeader__navLink {
    @apply text-lg;
  }

  .siteHeader__brand {
    max-width: 18rem;
    @apply m-0 py-2.5;
  }
  .siteHeader__searchInput {
    display: block;
  }

  .siteHeader__search {
    @apply flex;
  }
}

@screen lg {
  .siteHeader__nav {
    @apply justify-center;
  }
}
