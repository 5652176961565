.floatBlock {
  position: relative;
  display: block;
  margin: 2rem 0;
}

.floatBlock__imageContainer {
  min-width: 30%;
  position: relative;
  margin-right: 30px;
  margin-bottom: 30px;
  max-height: 700px;

  &:after {
    background-color: #ddd;
    opacity: 0.5;
    border-radius: 6px;
    content: "";
    position: absolute;
    top: 30px;
    left: 30px;
    right: -20px;
    bottom: -20px;
    z-index: 1;
    box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.05);
  }
}

.floatBlock__image {
  z-index: 2;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  display: block;
}

.floatBlock__heading {
  font-size: 24px;
  @apply text-primary font-normal;
  margin-bottom: 20px;
}

.floatBlock__content {
  margin-top: 40px;
  flex: auto;
}

/* .floatBlock__body {
  .rich-text {
    @apply text-xl;
  }
} */

.floatBlock__strapline {
  @apply text-xl;
  margin-bottom: 0.5rem;
}

.floatBlock--right {
  .floatBlock__image {
    box-shadow: -10px 10px 30px 0 rgba(0, 0, 0, 0.05);
  }

  .floatBlock__imageContainer {
    margin-left: 30px;
    margin-right: 0;

    &:after {
      left: -20px;
      right: 30px;
      box-shadow: -10px 10px 30px 0 rgba(0, 0, 0, 0.06);
    }
  }

  .floatBlock__content {
    padding-top: 50px;
    padding-left: 0;
  }
}

@screen sm {
  .floatBlock {
    display: flex;
  }

  .floatBlock__heading {
    @apply text-f1;
  }

  .floatBlock__content {
    /* padding-bottom: 120px; */
    padding-bottom: 0;
    padding-left: 80px;
    padding-top: 100px;
  }

  .floatBlock__imageContainer {
    margin-right: 40px;
  }

  .floatBlock__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    box-shadow: -10px 10px 30px 0 rgba(0, 0, 0, 0.05);
  }

  .floatBlock--right {
    .floatBlock__imageContainer {
      margin-left: 40px;
      order: 2;
    }

    .floatBlock__image {
      box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.05);
    }

    .floatBlock__content {
      padding-top: 50px;
      /* padding-bottom: 120px; */
      padding-bottom: 0;
      padding-right: 80px;
    }
  }
}

.floatBlock__body {
  .copyImage__features {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 60px;
    row-gap: 40px;
  }

  .copyImage__features--three-cols {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .copyImage__feature {
  }

  .copyImage__icon + .copyImage__featureHeading {
    margin-top: 18px;
  }

  .copyImage__featureHeading {
    @apply font-sans font-semibold text-xl;
  }

  .copyImage__featureCopy {
    @apply text-lg mb-0 mt-2;
  }

  .buttonGroup {
    margin-top: 40px;
  }

  .rich-text {
    margin-top: 30px;
  }
}
@screen lg {
  .floatBlock {
    margin: 100px 0;
  }

  .floatBlock__body {
    .copyImage__features {
      margin-top: 60px;
    }
    .rich-text {
      @apply text-xl;
      margin-top: 40px;
    }
  }
}
