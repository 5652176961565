.section--green {
  --white: theme("colors.white");
  --black: theme("colors.black");

  &.section--bg {
    background-color: theme("colors.green.light");
    color: var(--black);

    .section__title {
      color: var(--black);
    }

    .section__btn {
      background-color: theme("colors.green.vibrant");
      color: var(--white);
    }
  }
}

.section--yellow {
  --white: theme("colors.white");
  --black: theme("colors.black");

  &.section--bg {
    background-color: theme("colors.yellow.light");
    color: var(--black);

    .section__title {
      color: var(--black);
    }

    .section__btn {
      background-color: theme("colors.yellow.vibrant");
      color: var(--white);
    }
  }
}

.section--pink {
  --white: theme("colors.white");
  --black: theme("colors.black");

  &.section--bg {
    background-color: theme("colors.pink.light");
    color: var(--black);

    .section__title {
      color: var(--black);
    }

    .section__btn {
      background-color: theme("colors.pink.vibrant");
      color: var(--white);
    }
  }
}

.section--blue {
  --white: theme("colors.white");
  --black: theme("colors.black");

  &.section--bg {
    background-color: theme("colors.blue.light");
    color: var(--black);

    .section__title {
      color: var(--black);
    }

    .section__btn {
      background-color: theme("colors.blue.vibrant");
      color: var(--white);
    }
  }
}

.section--dark {
  --white: theme("colors.white");
  --black: theme("colors.black");

  &.section--bg {
    background-color: var(--black);
    color: var(--white);

    .section__title {
      color: var(--white);
    }

    .section__btn {
      background-color: var(--white);
      color: var(--white);

      &:hover {
        background-color: var(--white);
        color: var(--black);
      }
    }
  }
}

.section--light {
  --white: theme("colors.white");
  --black: theme("colors.black");

  &.section--bg {
    background-color: theme("colors.secondary.lighter");
    color: var(--black);

    .section__title {
      color: var(--black);
    }

    .section__btn {
      background-color: var(--black);
      color: var(--white);

      &:hover {
        background-color: var(--dark);
        color: var(--white);
      }
    }
  }
}
