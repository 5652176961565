/* Component Styles */

.thm--yellow {
  --name: "purple";
  --light: theme("colors.yellow.light");
  --default: theme("colors.yellow.DEFAULT");
  --dark: theme("colors.yellow.dark");
  --vibrant: theme("colors.yellow.vibrant");
  --white: theme("colors.white");
  --black: theme("colors.black");

  &.thm--border,
  .thm__border {
    border-color: var(--dark);
  }

  &.thm--color,
  .thm__color {
    color: var(--dark);
  }

  &.thm--bg,
  .thm__bg {
    background-color: var(--dark);
  }

  .thm__overlay {
    background-color: var(--default);
    opacity: 0.8;
    mix-blend-mode: multiply;
  }

  .line-detail {
    &:after {
      background-color: var(--vibrant);
    }
  }

  /* buttons */

  &.thm--bg.button {
    background-color: var(--vibrant);
    color: var(--white);

    .button__label.thm__color {
      color: inherit;
    }

    &:hover {
      background-color: var(--white);
      color: var(--vibrant);
    }
  }

  .thm__btn-block {
    background-color: var(--vibrant);
    color: var(--white);

    &:hover {
      background-color: var(--black);
    }
  }

  .thm__btn-hollow {
    background-color: transparent;
    border-color: var(--default);
    color: var(--default);
  }

  .thm__btn-blockAlt {
    background-color: var(--black);
    color: var(--white);
  }

  .thm__btn-hollowAlt {
    background-color: transparent;
    border-color: var(--black);
    color: var(--black);
  }

  /* ----- Contrast content on solid bg ----- */
  &.thm--bg,
  .thm__bg,
  .thm__contrast,
  .thm--bg &.thm--bg,
  .thm__bg &.thm--bg,
  &.section--bg .thm__contrast {
    .thm__color {
      color: var(--white);
    }

    .line-detail {
      &:after {
        background-color: var(--light);
      }
    }

    /* buttons */

    .thm__btn-block {
      background-color: var(--black);
      color: var(--white);

      &:hover {
        background-color: var(--white);
        color: var(--black);
      }
    }

    .thm__btn-hollow {
      background-color: transparent;
      border-color: var(--white);
      color: var(--white);

      &:hover {
        background-color: var(--white);
        color: var(--black);
      }
    }

    .thm__btn-blockAlt {
      background-color: var(--white);
      color: var(--black);

      &:hover {
        background-color: var(--black);
        color: var(--white);
      }
    }

    .thm__btn-hollowAlt {
      background-color: transparent;
      border-color: var(--white);
      color: var(--white);

      &:hover {
        border-color: var(--black);
        background-color: var(--black);
        color: var(--white);
      }
    }
  }

  /* ---------------------- Pale theme -------------------- */

  &.thm--pale {
    &.thm--border,
    .thm__border {
      border-color: var(--dark);
    }

    &.thm--color,
    .thm__color {
      color: var(--black);
    }

    &.thm--bg,
    .thm__bg {
      background-color: var(--light);
    }

    .thm__overlay {
      background-color: var(--light);
      opacity: 0.7;
    }

    .line-detail {
      &:after {
        background-color: var(--dark);
      }
    }

    /* buttons */

    .thm__btn-block {
      background-color: var(--dark);
      color: var(--white);
    }

    .thm__btn-hollow {
      color: var(--dark);
      background-color: transparent;
      border-color: var(--dark);
    }

    .thm__btn-blockAlt {
      background-color: var(--black);
      color: var(--white);
    }

    .thm__btn-hollowAlt {
      color: var(--black);
      background-color: transparent;
      border-color: var(--black);
    }

    /* ----- Pale with bg ------ */
    &.thm--bg,
    .thm__bg,
    .thm__contrast,
    .thm--bg &.thm--bg,
    .thm__bg &.thm--bg,
    &.section--bg .thm__contrast {
      &.thm--border,
      .thm__border {
        border-color: var(--dark);
      }

      &.thm--color,
      .thm__color {
        color: var(--black);
      }

      &.thm--bg,
      .thm__bg {
        background-color: var(--light);
      }

      .line-detail {
        &:after {
          background-color: var(--vibrant);
        }
      }

      /* buttons */
      .thm__btn-block {
        background-color: var(--vibrant);
        color: var(--white);

        &:hover {
          background-color: var(--white);
          color: var(--vibrant);
        }
      }

      .thm__btn-hollow {
        color: var(--vibrant);
        background-color: transparent;
        border-color: var(--vibrant);

        &:hover {
          background-color: var(--vibrant);
          color: var(--white);
        }
      }

      .thm__btn-blockAlt {
        background-color: var(--black);
        color: var(--white);

        &:hover {
          background-color: var(--vibrant);
          color: var(--white);
        }
      }

      .thm__btn-hollowAlt {
        color: var(--black);
        background-color: transparent;
        border-color: var(--black);

        &:hover {
          background-color: var(--vibrant);
          border-color: var(--vibrant);
          color: var(--white);
        }
      }
    }
  }
}
